<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0">
            <div class="d-flex p-0">
                <div class="card-title text-gray-600">
                    <div class="filter d-flex align-items-center position-relative fs-6">
                        <div class="filter-item">
                            <span>CARI</span>
                            <el-input v-model="filter.search" placeholder="Search" clearable @keyup.enter="onFilter()"/>
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-icon btn-light-primary me-3" @click="onFilter(), currentPage = 1"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter(), currentPage = 1"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card-toolbar" style="margin-left:auto">
                    <div class="d-flex justify-content-end" data-kt-table-toolbar="base">
                        <button type="button" class="btn btn-light-primary me-3" v-show="false">
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black" />
                                    <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black" />
                                    <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
                                </svg>
                            </span>
                            Export
                        </button>
                        <router-link class="btn btn-primary" to="/supplier/create_request">Create</router-link>
                    </div>
                </div>
            </div>
            <div class="my-3 p-0 text-gray-600 text-uppercase fw-bolder" v-if="filterActive">
                <span>Hasil filter</span>
            </div>
        </div>
        <div class="card-body pt-0 tab-content">
            <div ref="tableRef" class="table-fixed-header">
                <table class="table table-rounded table-row-dashed cursor-pointer table-hover" :class="{'overlay': loadingTable, 'overlay-block': loadingTable}">
                    <thead>
                        <tr class="text-start fw-bolder fs-7 gs-0 text-gray-400 border-gray-200 text-uppercase">
                            <th class="w-50px">#</th>
                            <th>Tanggal</th>
                            <th>Kode Request</th>
                            <th>Judul</th>
                            <th>Warehouse</th>
                            <th>Vendor</th>
                            <th>User</th>
                            <th>Approved By</th>
                            <th class="text-end w-150px">QTY</th>
                            <th class="text-end w-150px">QTY FINAL</th>
                            <th class="text-end">Total</th>
                            <th class="text-end">PO</th>
                            <th class="text-end">Status</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-600">
                        <tr v-for="(item, i) in tableData" :key="i">
                            <td class="w-50px">{{ item.index }}</td>
                            <td>{{ item.created_at }}</td>
                            <td>{{ item.kode_request_po }}</td>
                            <td>{{ item.title }}</td>
                            <td>{{ item.warehouse }}</td>
                            <td>{{ item.vendor }}</td>
                            <td>{{ item.nama_user }}</td>
                            <td>
                                <el-popover
                                    placement="top"
                                    :width="250"
                                    trigger="hover"
                                >
                                    <div v-if="item.is_full_approve" class="mb-4">
                                        <div class="badge badge-success fw-boldest m-0 fs-8">Sudah Approved</div>
                                    </div>
                                    <div v-else class="mb-4">
                                        <div class="badge badge-warning fw-boldest m-0 fs-8">Approval Belum Lengkap</div>
                                    </div>
                                    <div>
                                        {{ item.user_approval.length > 0 ? item.user_approval.map(o => o.name).join(', ').toUpperCase() : 'Belum di-approve' }}
                                    </div>
                                    <template #reference>
                                        <button class="btn btn-secondary btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">{{ item.user_approval.map(o => o.name).slice(0, 1).join(',').toUpperCase() || '-' }}{{ item.user_approval.map(o => o.name).length > 1 ? '...' : '' }}</button>
                                    </template>
                                </el-popover>
                            </td>
                            <td class="text-end">{{ item.qty }}</td>
                            <td class="text-end">{{ item.qty_final || 0 }}</td>
                            <td class="text-end">{{ formatIDR(item.hpp) }}</td>
                            <td class="text-end">
                                <span v-if="item.is_purchase_order"><i class="fas fa-check text-success"></i></span>
                                <span v-else><i class="fas fa-times text-danger"></i></span>
                            </td>
                            <td class="text-end">
                              <span class="badge badge-dark" v-if="item.status == 'PENDING'">{{ item.status }}</span>
                              <span class="badge badge-info" v-if="item.status == 'REQUEST'">{{ item.status }}</span>
                              <span class="badge bg-gray-500" v-if="item.status == 'DIREVISI'">{{ item.status }}</span>
                              <span class="badge badge-success" v-if="item.status == 'APPROVED'">{{ item.status }}</span>
                              <span class="badge badge-secondary" v-if="item.status == 'DITINJAU'">{{ item.status }}</span>
                              <span class="badge badge-warning" v-if="item.status == 'BERMASALAH'">{{ item.status }}</span>
                              <span class="badge badge-danger" v-if="item.status == 'DIBATALKAN'">{{ item.status }}</span>
                            </td>
                            <td class="text-end">
                                <div class="d-flex justify-content-end">
                                    <router-link :to="'/supplier/request_po_detail/'+item.kode_request_po" class="btn btn-primary btn-sm mx-1">
                                      View
                                    </router-link>
                                    <button class="btn btn-success btn-sm mx-1" title="Create Purchase Order" v-if="item.status == 'APPROVED' && !validateRole(udata.role_id.toString().split(','), item.approval_role_id.toString().split(',')) && !item.is_purchase_order" @click="confirmPurchaseOrder(item)">PO</button>
                                    <el-popconfirm 
                                        v-if="item.status == 'PENDING' && !validateRole(udata.role_id.toString().split(','), item.approval_role_id.toString().split(','))"
                                        confirm-button-text="OK"
                                        cancel-button-text="No"
                                        :icon="InfoFilled"
                                        title="Apakah yakin ingin ajukan request?"  
                                        @confirm="sendRequest(item)"
                                    >
                                        <template #reference>
                                            <button class="btn btn-info btn-sm mx-1">
                                              Request
                                            </button>
                                        </template>
                                    </el-popconfirm>
                                    <el-popconfirm 
                                        v-if="item.status == 'REQUEST' && !validateRole(udata.role_id.toString().split(','), item.approval_role_id.toString().split(','))"
                                        confirm-button-text="OK"
                                        cancel-button-text="No"
                                        :icon="InfoFilled"
                                        title="Apakah yakin ingin batalkan request?"  
                                        @confirm="cancelRequest(item)"
                                    >
                                        <template #reference>
                                            <button class="btn btn-secondary btn-sm mx-1">
                                              Batalkan
                                            </button>
                                        </template>
                                    </el-popconfirm>
                                    <el-popconfirm 
                                        v-if="item.status == 'REQUEST' && validateRole(udata.role_id.toString().split(','), item.approval_role_id.toString().split(','))"
                                        confirm-button-text="OK"
                                        cancel-button-text="No"
                                        :icon="InfoFilled"
                                        title="Apakah yakin meninjau request?" 
                                        @confirm="reviewRequest(item)"
                                    >
                                        <template #reference>
                                            <button class="btn btn-secondary btn-sm mx-1">
                                              Tinjau
                                            </button>
                                        </template>
                                    </el-popconfirm>
                                    <el-popconfirm
                                        v-if="item.status == 'PENDING' && !validateRole(udata.role_id.toString().split(','), item.approval_role_id.toString().split(','))"
                                        confirm-button-text="OK"
                                        cancel-button-text="No"
                                        :icon="InfoFilled"
                                        title="Apakah yakin ingin menghapus request?"
                                        @confirm="onDelete(item)"
                                    >
                                        <template #reference>
                                            <a href="javascript:void(0)" class="btn btn-danger btn-sm ms-1" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                                                <i class="fas fa-trash-alt"></i>
                                            </a>
                                        </template>
                                    </el-popconfirm>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <div class="overlay-layer card-rounded bg-dark bg-opacity-5" v-if="loadingTable">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </table>
            </div>
            <div class="text-center my-3" v-show="tableData.length > 0">
                <el-pagination 
                    background 
                    :page-size="50"
                    layout="prev, pager, next" 
                    :page-count="pagination.total_page" 
                    @next-click="handlePageChange" 
                    @prev-click="handlePageChange" 
                    @current-change="handlePageChange" 
                    @update:current-page="pagination.page"
                />
            </div>
        </div>

        <el-dialog v-model="modal" :title="'Create Purchase Order'" :close-on-click-modal="true" width="50%" align-center>
            <div class="fs-6 mb-3">
                Apakah yakin ingin membuat purchase order {{kode_request_po}}? 
            </div>
            <Form
                class="form w-100"
                :validation-schema="purchase_order"
                @submit="createPurchaseOrder()"
            >
                <div class="fw-row mb-3">
                    <Field type="text" v-model="purchase_order.title" name="title" v-slot="{ field }" >
                        <input v-bind="field" class="form-control" placeholder="Masukkan Judul">
                    </Field>
                    <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                            <ErrorMessage name="title" />
                        </div>
                    </div>
                </div>
                <div class="row justify-content-end gs-1">
                    <div class="col-auto">
                        <button class="btn btn-danger btn-sm" @click="modal = false">Cancel</button>
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-primary btn-sm" type="submit">Ok</button>
                    </div>
                </div>
            </Form>
        </el-dialog>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox, ElLoading, Elpagination, FormInstance, FormRules } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

export default defineComponent({
    components: { 
        //Datatable,
        Form, Field, ErrorMessage,
        //'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const route = useRoute()
        const router = useRouter()

        const store = useStore()

        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)
        const img_url_logo= ref(process.env.VUE_APP_IMAGE_LOGOS)

        const loadingTable = ref(false);
        const loadingSubmit = ref(false);
        const loadingButton = ref(false);
        const tableRef = ref(null)

        const filterActive = ref(false)
        const filter = reactive({
            tipe: null,
            search: null,
        })

        const udata = computed(() => {
            return store.getters.currentUser
        }) 

        const formRef = ref(null)

        const modal = ref(false)
        const kode_request_po = ref('');

        const tableData = reactive([]);
        const totalRecordCount = ref(0);

        const tempData = reactive(Yup.object().shape({
            id: null,
            title: Yup.string().required().label('Nama'),
            parent_id: Yup.number().required().label('Parent'),
            route: Yup.string().required().label('Route'),
            sequence: '',
            active: Yup.number().required().label('Status'),
        }))

        const purchase_order = ref(Yup.object().shape({
            title: Yup.string().required().label('Judul').min(3, 'Judul minimal 3 karakter')
        }))
        
        const onFilter = async (type) => {
            filterActive.value = false
        
            loadingTable.value = true

            await getData()

            loadingTable.value = false

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async (type) => {
          Object.keys(filter).map(key => filter[key] = null)

          loadingTable.value = true
          filterActive.value = false

          await getData()

          loadingTable.value = false
        }

        // PAGINATION
        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

        const handlePageChange = async (page) => {
            loadingTable.value = true
            tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position

            await getData(page)
            loadingTable.value = false
        }

        const validateRole = (arr1, arr2) => {
            let arr = arr1.filter(value => arr2.includes(value))

            if(arr1[0] == 1 || arr1[1] == 1) {
                return false
            }

            if(arr.length > 0) {
                return true
            }

            return false;
        } 

        const getData = async (page = 1) => {
            try {
                loadingTable.value = true
                await tableData.splice(0)

                let search = filter.search ? '&search=' + filter.search : '';

                await ApiService.get("supplier/request_po?page=" + page + search)
                .then(async ({ data }) => {
                    pagination.value = data.data.pagination
                    Object.assign(tableData, data.data.data)
                })
                .catch(({ response }) => {
                    console.log('Error getting request po');
                });

            } catch(e) {
                console.error(e)
            }

            loadingTable.value = false
        }

        const onDelete = async(row) => {
            try {
                if(row.status != 'PENDING') {
                    throw new Error('Hapus sudah tidak berlaku')
                }

                await ApiService.post("supplier/delete_request_po/"+row.kode_request_po)
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    getData()
                        
                })
                .catch(({ response }) => {
                    Swal.fire({
                        title: 'Request error',
                        icon: "error",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 2000
                    });
                });

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }

        const sendRequest = async(row) => {
            const loading = ElLoading.service()
            
            try {

                if(row.status != 'PENDING') {
                    throw new Error('Permintaan sudah dikirim')
                }

                await ApiService.post("supplier/send_request_po/"+row.kode_request_po)
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    getData()
                        
                })
                .catch(({ response }) => {
                    Swal.fire({
                        title: 'Request error',
                        icon: "error",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 2000
                    });
                });

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

            loading.close()
        }

        const reviewRequest = async(row) => {
            const loading = ElLoading.service()
            
            try {
                if(row.status != 'REQUEST') {
                    throw new Error('Telah ditinjau')
                }

                await ApiService.post("supplier/review_request_po/"+row.kode_request_po)
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    getData()
                        
                })
                .catch(({ response }) => {
                    Swal.fire({
                        title: 'Request error',
                        icon: "error",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 2000
                    });
                });

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

            loading.close()
        }

        const cancelRequest = async(row) => {
            const loading = ElLoading.service()
            
            try {

                if(row.status != 'REQUEST') {
                    throw new Error('Permintaan sudah tidak berlaku')
                }

                await ApiService.post("supplier/cancel_request_po/"+row.kode_request_po)
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    getData()
                        
                })
                .catch(({ response }) => {
                    Swal.fire({
                        title: 'Request error',
                        icon: "error",
                        buttonsStyling: false,
                        showConfirmButton: false,
                        timer: 2000
                    });
                });

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

            loading.close()
        }

        const confirmPurchaseOrder = (item) => {
            try {
                modal.value = true
                kode_request_po.value = item.kode_request_po

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 3000
                });
            }
        }

        const createPurchaseOrder = async () => {
            const loading = ElLoading.service()
            modal.value = false

            try {

                await ApiService.post('supplier/create_purchase_order', { kode_request_po: kode_request_po.value, title: purchase_order.value.title })
                .then(({ data }) => {
                    if(data.error) {
                        throw new Error(data.messages)
                    }

                    Swal.fire({
                        title: data.messages,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    getData()
                })
                .catch(({ response }) => {  
                    throw new Error(response.data.messages)
                });

            } catch(e) {
                Swal.fire({
                    title: 'Something Error',
                    icon: "error",
                    showConfirmButton: false,
                    timer: 3000
                });

            }

            loading.close()
        }

        onMounted(() => {
            setCurrentPageBreadcrumbs(route.meta.title, ['Supplier', route.meta.title]);

            getData()
        })

        return {
            formatIDR,
            modal, 
            loadingTable, loadingButton, loadingSubmit, tableRef, img_url_mockup, img_url_desain, img_url_logo,
            pagination, handlePageChange,
            udata, validateRole, purchase_order,
            onFilter, resetFilter, filter, filterActive,
            formRef, kode_request_po,
            tableData, tempData, onDelete, sendRequest, cancelRequest, reviewRequest, confirmPurchaseOrder, createPurchaseOrder,
        }
    }
})
</script>